const MessageString = {
  distanceTooLow:
    "The HCP's preferred range is too small and there are shifts within 100 miles of them",
  viewFutureMonth: "They've navigated to a future month with no shifts yet",
  viewPastMonth: "They've navigated to a past month",
  noShiftForHCP: "There are simply no shifts within 100 miles of the HCP",
  noAddressForHCP: "The HCP doesn't have an address",
};

export { MessageString };
