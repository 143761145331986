import { Clipboard } from "@capacitor/clipboard";
import { Keyboard } from "@capacitor/keyboard";
import { Preferences } from "@capacitor/preferences";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPopover,
  isPlatform,
} from "@ionic/react";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { useCallback, useEffect, useState } from "react";

import { ShiftTrip } from "../../app/locationTracking/locationTracking.types";
import { locationService } from "../../app/openShifts/urgentShifts/locationService";
import { LocalStorage } from "../../app/store/session";
import { Shift } from "../interface/src";

enum LOCATION_PERMISSION_STATUS {
  GRANTED = "GRANTED",
  DENIED = "DENIED",
  UNKNOWN = "UNKNOWN",
}

interface RadarSdkTestPanelProps {
  shift?: Shift | null;
}

export function RadarSdkTestPanel(props: RadarSdkTestPanelProps) {
  const [showPopover, setShowPopover] = useState(false);
  const ldFlags = useCbhFlags();
  const [tag, setTag] = useState("");
  const [currentTrip, setCurrentTrip] = useState("");
  const [permission, setPermission] = useState<string>(LOCATION_PERMISSION_STATUS.UNKNOWN);
  const [externalId, setExternalId] = useState("");
  const { showSuccessToast, showErrorToast } = useToast();

  const radarSdkConfig = ldFlags[CbhFeatureFlag.RADAR_SDK_CONFIG_ONSITE_TRACKING];

  const startLocationTracking = useCallback(() => {
    const radarConfigsByPlatform = isPlatform("ios")
      ? radarSdkConfig?.ios
      : radarSdkConfig?.android;

    locationService.startTracking(radarConfigsByPlatform);
    showSuccessToast("Tracking started!");
  }, [radarSdkConfig, showSuccessToast]);

  useEffect(() => {
    Preferences.get({
      key: LocalStorage.SHIFT_TRIP,
    }).then((storedShiftTrip) => {
      const parsedShiftTrip = JSON.parse(storedShiftTrip.value as string) as ShiftTrip;
      setCurrentTrip(parsedShiftTrip?.tripId);
    });
  }, []);

  const handleStartTrip = async () => {
    const shift = props?.shift;
    const facility = shift?.facility;
    const externalId = facility
      ? `${shift._id}-${facility.userId}`
      : (Math.random() + 1).toString(36).substring(7); // to generate random id
    const destinationGeofenceTag = facility ? "Facility" : tag;
    const destinationGeofenceExternalId = facility ? facility.userId : externalId;
    const metadata = facility
      ? {
          shiftId: shift._id,
          shiftStart: new Date().toISOString(),
        }
      : undefined;

    await locationService.startTrip({
      options: {
        externalId,
        destinationGeofenceTag,
        destinationGeofenceExternalId,
        mode: "car",
        metadata,
      },
    });
    showSuccessToast("Trip started!");
  };

  const handleRequestPermission = useCallback(async () => {
    const { status } = await locationService.getLocationPermissionsStatus();
    setPermission(status);
    if (
      status === LOCATION_PERMISSION_STATUS.UNKNOWN ||
      status === LOCATION_PERMISSION_STATUS.DENIED
    ) {
      locationService.requestLocationPermissions();
    } else {
      showSuccessToast("Permission is already granted");
    }
  }, [showSuccessToast]);

  const handleCopyShiftId = useCallback(async () => {
    Clipboard.write({
      string: currentTrip,
    });
    showErrorToast("Shift ID copied to clipboard!");
  }, [currentTrip, showErrorToast]);

  return (
    <div
      tabIndex={0}
      role="button"
      style={{ position: "absolute", bottom: 0 }}
      onClick={() => Keyboard.hide()}
      data-testid="radar-sdk-testpanel"
    >
      <IonButton onClick={() => setShowPopover(true)}>Radar SDK</IonButton>

      <IonPopover
        isOpen={showPopover}
        showBackdrop
        backdropDismiss={true}
        onDidDismiss={(e) => setShowPopover(false)}
      >
        <IonContent className="ion-padding" style={{ padding: "5px" }}>
          <IonButton expand="block" size="small" onClick={handleRequestPermission}>
            {permission}
          </IonButton>
          <IonButton expand="block" size="small" onClick={() => startLocationTracking()}>
            Start tracking
          </IonButton>
          <IonButton size="small" expand="block" onClick={() => locationService.stopTracking()}>
            Stop tracking
          </IonButton>
          {!props?.shift && (
            <>
              <IonItem lines="none" className="form-item-wrapper">
                <IonLabel position="stacked">Geofence tag</IonLabel>
                <IonInput
                  className="form-input"
                  placeholder="Geofence tag"
                  onIonChange={(e) => setTag(e.detail.value!)}
                  value={tag}
                />
              </IonItem>

              <IonItem lines="none" className="form-item-wrapper">
                <IonLabel position="stacked">Geofence external id</IonLabel>
                <IonInput
                  className="form-input"
                  placeholder="Geofence external id"
                  onIonChange={(e) => setExternalId(e.detail.value!)}
                  value={externalId}
                />
              </IonItem>
            </>
          )}

          <IonButton expand="block" size="small" onClick={handleStartTrip}>
            Start trip
          </IonButton>
          <IonButton size="small" expand="block" onClick={() => locationService.completeTrip()}>
            Complete trip
          </IonButton>

          {props?.shift && (
            <>
              <IonLabel position="stacked">Geofence</IonLabel>
              <IonButton
                size="small"
                expand="block"
                style={{
                  fontSize: 10,
                }}
                disabled={!handleCopyShiftId}
                onClick={handleCopyShiftId}
              >
                {currentTrip || "None"}
              </IonButton>
            </>
          )}
        </IonContent>
      </IonPopover>
    </div>
  );
}
