import { RefObject, useEffect, useRef, useState } from "react";

/*
The value of threshold should be between 0 and 1, it determines the percentage of of the element that needs to be in the viewport for it to be considered on screen.
The default for this is 1, which means the component needs to be completely in the viewport.
*/
export default function useOnScreen(ref: RefObject<HTMLElement>, threshold = 1) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      { threshold }
    );
  }, [threshold]);

  useEffect(() => {
    if (ref.current && observerRef.current) {
      observerRef.current.observe(ref.current);
    }
    return () => observerRef?.current?.disconnect();
  }, [ref]);

  return isOnScreen;
}
