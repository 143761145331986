import { FormEvent, useState } from "react";

// Function to format input field to Phone Number: 1234567890 converted to (123) 456-7890
export const formatNumberToPhoneNumber = (v = "") =>
  v
    .replace(/[^0-9x]/g, "")
    .replace(/^(\w{3})/, "($1) ")
    .replace(/\s(\w{3})/, " $1-")
    .replace(/(\w)-(\w{4}).*/, "$1-$2");

const formatPhoneNumberToNumber = (number: string) => number.replace(/[^0-9x]/g, "");

export const useTelephoneNumber = ({
  initialValue,
  onNext,
}: {
  initialValue: string;
  onNext?: (e: FormEvent<HTMLElement>) => void;
}) => {
  const [formattedPhoneNumber, setPhoneNumber] = useState(
    formatNumberToPhoneNumber(initialValue ?? "")
  );

  const onChange = (event) => {
    setPhoneNumber(event.currentTarget.value);
  };

  // To format the input only when the key pressed is not backspace and delete
  const onKeyUp = (event) => {
    if (event.key !== "Backspace" && event.key !== "Delete") {
      setPhoneNumber(formatNumberToPhoneNumber(event.target.value));
    } else {
      setPhoneNumber(event.currentTarget.value);
    }
    if (event.key === "Enter") {
      onNext && onNext(event);
    }
  };

  // To prevent inputs which are not number
  const onKeyPress = (event) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  };

  return {
    onChange,
    onKeyPress,
    onKeyUp,
    formattedPhoneNumber,
    phoneNumber: formatPhoneNumberToNumber(formattedPhoneNumber),
    setPhoneNumber,
  };
};
