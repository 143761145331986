import { isPlatform } from "@ionic/core";
import { SegmentCapacitorPlugin } from "capacitor-segment-plugin";
import { Deploy } from "cordova-plugin-ionic";
class SegmentPluginHelper {
  isInitialized: boolean;
  otaBuildId: string | undefined;

  constructor() {
    this.isInitialized = false;
    this.otaBuildId = undefined;
    this.createSegmentInstance = this.createSegmentInstance.bind(this);
  }

  async createSegmentInstance(segmentKeys: Record<string, string>): Promise<void> {
    if (this.isInitialized) {
      return;
    } else {
      const { iosKey, androidKey, webKey } = segmentKeys || {};
      if (isPlatform("capacitor")) {
        // Do not initialize when already an instance exists.
        const { instanceExist } = await SegmentCapacitorPlugin.isInstanceExist();
        if (instanceExist) {
          return;
        }
        const currentOtaVersion = await Deploy.getCurrentVersion();
        this.otaBuildId = currentOtaVersion?.buildId;
        const key = isPlatform("ios") ? iosKey : androidKey;
        if (this.isInitialized) {
          return;
        }
        SegmentCapacitorPlugin.setUp({
          key,
          trackLifecycle: true,
        });
        this.isInitialized = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Element implicitly has an 'any' type because index expression is not of type 'number'.
        const analytics: any = (window["analytics"] = window["analytics"] || []);
        if (this.isInitialized) {
          return;
        }
        if (!analytics.initialize) {
          if (analytics.invoked) {
            window.console && console.error && console.error("Segment snippet included twice.");
          } else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (t: unknown) {
              return function (...args: unknown[]) {
                args.unshift(t);
                analytics.push(args);
                return analytics;
              };
            };
            for (let t = 0; t < analytics.methods.length; t++) {
              const e = analytics.methods[t];
              analytics[e] = analytics.factory(e);
            }
            analytics.load = function (t: string, e: Record<string, unknown>) {
              const n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
              const a = document.getElementsByTagName("script")[0]!;
              a.parentNode?.insertBefore(n, a);
              analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = "4.1.0";
            analytics.load(webKey);
          }
        }
        this.isInitialized = true;
      }
    }
  }

  async capacitorTrack(
    eventName: string,
    properties?: {
      [K: string]: any;
    }
  ): Promise<void> {
    if (isPlatform("capacitor")) {
      await SegmentCapacitorPlugin.track({
        eventName,
        properties,
        options: { context: { otaBuildId: this.otaBuildId } },
      });
    }
  }

  webTrack(
    eventName: string,
    properties?: {
      [K: string]: any;
    }
  ): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Element implicitly has an 'any' type because index expression is not of type 'number'.
    window["analytics"].track(eventName, { ...properties });
  }

  async capacitorIdentify(userID: string, traits: Record<string, unknown>): Promise<void> {
    if (isPlatform("capacitor")) {
      await SegmentCapacitorPlugin.identify({ userID, traits });
    }
  }

  webIdentify(userId: string, payload: Record<string, unknown>): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Element implicitly has an 'any' type because index expression is not of type 'number'.
    window["analytics"].identify(userId, payload, {
      integrations: {},
    });
  }
}

const segmentPluginHelper = new SegmentPluginHelper();

export { segmentPluginHelper };
