import { useQuery } from "@apollo/client";
import {
  IReferenceFormResponse,
  REFERENCE_REQUIREMENT_STATE,
  fetchReferences,
} from "@src/app/api/references";
import { GET_HCP_REQUIREMENTS } from "@src/app/documents/gql";
import { REFERENCES_REQUIREMENT_ID } from "@src/app/professionalReferences/constants";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import { useEffect, useState } from "react";

export const useGetReferenceStatus = () => {
  const [referenceLoading, setReferenceLoading] = useState(false);
  const [references, setReferences] = useState<IReferenceFormResponse>();

  // disabling the query since we want to only listen for changes here, without fetching
  const { data: worker } = useGetWorker({ enabled: false });

  const hcpId = worker?.userId;

  const isProfessionalReferencesEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_PROFESSIONAL_REFERENCES,
    { defaultValue: false }
  );

  const isProfessionalReferencesEnabledAndAgentExists = worker
    ? isProfessionalReferencesEnabled
    : undefined;

  useEffect(() => {
    const getReferences = async () => {
      if (!hcpId) {
        return;
      }
      try {
        setReferenceLoading(true);
        const references = await fetchReferences();
        setReferences(references);
      } finally {
        setReferenceLoading(false);
      }
    };

    getReferences();
  }, [hcpId]);

  const { data: fulfilledRequirements, loading: fullFilledRequirementLoading } = useQuery(
    GET_HCP_REQUIREMENTS,
    {
      variables: {
        hcpId,
        filter: { requirementId: REFERENCES_REQUIREMENT_ID },
      },
      fetchPolicy: "cache-and-network",
      skip: !hcpId,
    }
  );

  let status = REFERENCE_REQUIREMENT_STATE.NOT_STARTED;

  if (fulfilledRequirements?.hcpRequirementList?.length) {
    status = REFERENCE_REQUIREMENT_STATE.COMPLETE;
  }
  if (fulfilledRequirements?.hcpRequirementList?.length && (references?.count ?? 0) > 0) {
    status = references?.status as REFERENCE_REQUIREMENT_STATE;
  }

  // loading would be true till the APIs resolve and the flag value is evaluated.
  const isLoading =
    referenceLoading ||
    fullFilledRequirementLoading ||
    typeof isProfessionalReferencesEnabledAndAgentExists === "undefined";

  const variant = isProfessionalReferencesEnabledAndAgentExists ? "v2_automated" : "v1_manual";

  return {
    status,
    isLoading,
    variant,
  };
};
